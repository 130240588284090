@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    color-scheme: light;
}

@media (prefers-color-scheme: dark) {
    :root {
        color-scheme: dark;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
